module controllers {
    'use strict';
    export module sales {
        interface ISalesSunUpdateScope extends ng.IScope { }

        interface ISalesUpdateParams extends ng.ui.IStateParamsService {
            soId: number,
            sosId: number
        }

        export class SalesOrderSunUpdateCtrl {
            static $inject = [
                "$scope",                
                '$rootScope',                
                '$q',
                '$stateParams',               
                "$anchorScroll",
                "$state",                
                "$timeout",
                '$transitions',
                'bsLoadingOverlayService',
                'salesOrderService',
                'generalService',
                'sundryItemService',
                'vatRateService',
                'costModelElementService',
                'currencyService',
                'classificationValueService',
                'unitOfMeasureService',
                'salesOrderSundryItemService'
                ];

            salesOrderId: number;
            salesOrderSundryItemId: number;
            soSundryItem: interfaces.sales.ISalesOrderSundryItem;
            breadCrumbDesc: string;

            constructor(
                private $scope: ISalesSunUpdateScope,               
                private $rootScope: interfaces.applicationcore.IRootScope,                
                private $q: ng.IQService,
                private $stateParams: ISalesUpdateParams,              
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,               
                public  $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public bsLoadingOverlayService,
                private salesOrderService: interfaces.sales.ISalesOrderService,
                public generalService: interfaces.applicationcore.IGeneralService,
                public sundryItemService: interfaces.master.ISundryItemService,
                private vatRateService: interfaces.master.IVatRateService,
                private costModelElementService: interfaces.costing.ICostModelElementService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                private salesOrderSundryItemService: interfaces.sales.ISalesOrderSundryItemService,
                          
            ) {
                this.salesOrderId = this.$stateParams.soId;
                this.salesOrderSundryItemId = this.$stateParams.sosId;

                bsLoadingOverlayService.wrap({
                    referenceId: 'so.sun.update'
                },
                    () => {
                        return this.loadSoSundryItem();
                    });

            }

            loadSoSundryItem() {
                var defer = this.$q.defer();

                this.salesOrderSundryItemService.getDetail().get({ Id: this.$stateParams.sosId, soId: this.$stateParams.soId }, (result) => {
                    this.soSundryItem = result;

                    if (this.soSundryItem.SundryItem)
                        this.breadCrumbDesc = this.soSundryItem.SundryItem.Code;

                    defer.resolve();
                }, (errorResponse) => {
                    defer.reject();
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
                return defer.promise;
            }

            Save() {
                this.generalService.displayConfirmationBox("Confirm", "Save Sales Order Sundry Item?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'so.sun.update'
                        },
                            () => {
                                return this.salesOrderSundryItemService.save().save(this.soSundryItem, (msgHandler) => {
                                    this.generalService.displayMessageHandler(msgHandler);

                                    if (this.soSundryItem.Id)
                                        this.loadSoSundryItem();
                                    else {
                                        this.$state.params["sosId"] = Number(msgHandler.ID);
                                        this.$state.go('auth.SalesOrders.Update.SundryItem', this.$state.params, { reload: 'auth.SalesOrders.Update.SundryItem' });
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });
                    }
                });
            }

            SaveClose() {
                this.generalService.displayConfirmationBox("Confirm", "Save Sales Order Sundry Item?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'di.sun.update'
                        },
                            () => {
                                return this.salesOrderSundryItemService.save().save(this.soSundryItem, (msgHandler: interfaces.applicationcore.IMessageHandler) => {
                                    this.generalService.displayMessageHandler(msgHandler);
                                    if (!msgHandler.HasErrorMessage) {
                                        this.$state.go("^");
                                        this.$anchorScroll("topAnchor");
                                    } else {
                                        this.$anchorScroll("topAnchor");
                                    }
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });
                    }
                });

            }

            Close() {
                this.$state.go("^");
            }

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadSundryItem(searchText: string) {
                if (this.soSundryItem) {
                    return this.sundryItemService.getDropdownList(this.soSundryItem.OwnerEntityId,false, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }


            loadCostModelElement(searchText: string) {
                if (this.soSundryItem) {
                    return this.costModelElementService.getDropdownList(this.soSundryItem.OwnerEntityId).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadVatRate(searchText: string) {
                if (this.soSundryItem) {
                    return this.vatRateService.getDropdownList().query({ ownerEntityId: this.soSundryItem.OwnerEntityId, countryId: this.soSundryItem.CountryOfLoadingId }, () => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            sundryItemChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'so.sun.update'
                    },
                        () => {
                            return this.sundryItemService.getDefault().get({ sunId: model.Id, countryId: this.soSundryItem.CountryOfLoadingId }, (result: interfaces.master.ISundryItemDefault) => {
                                this.setDefaults(result);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            setDefaults(defaults: interfaces.master.ISundryItemDefault) {
                this.soSundryItem.SundryItemDataDescriptionId = defaults.ShortDataDescriptionId;
                this.soSundryItem.IsDutiable = false;
                this.soSundryItem.IsIncludedInPrice = false;
                this.soSundryItem.VATRate = defaults.VatRateRate;
                this.soSundryItem.VATRateRate = defaults.VatRate;
                this.soSundryItem.CostModelElement = defaults.CostModelElement;

                this.calculateVatValue();
            }

            vatRateChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'di.sun.update'
                    },
                        () => {
                            return this.vatRateService.getVatRate().get({ mvrId: model.Id }, (vatRate: interfaces.master.IVatRate) => {
                                this.soSundryItem.VATRate = vatRate.CurrentRate;
                                this.calculateVatValue();
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                } else {
                    this.soSundryItem.VATRate = 0;
                    this.calculateVatValue();
                }
            }

            exchangeRateChanged() {
                this.calculateBaseSellingValue(this.soSundryItem.SellingValue, this.soSundryItem.VATValue, this.soSundryItem.ExchangeRate);
            }

            calculateSellingValue() {
                this.soSundryItem.SellingValue = Number((this.soSundryItem.Quantity * this.soSundryItem.SellingPrice).toFixed(2));
                this.calculateVatValue();
            }

            calculateVatValue() {
                this.soSundryItem.VATValue = 0;
                if (!this.soSundryItem.IsZeroRated)
                    this.soSundryItem.VATValue = Math.round((this.soSundryItem.SellingValue * (this.soSundryItem.VATRate / 100)) * 100) /100;
                this.calculateBaseSellingValue(this.soSundryItem.SellingValue, this.soSundryItem.VATValue, this.soSundryItem.ExchangeRate);
            }

            calculateBaseSellingValue(sellingValue: number, vatValue: number, exchangeRate: number) {
                this.soSundryItem.BaseCurrencyValue = Number(((sellingValue + vatValue) * exchangeRate).toFixed(2));
            }
           
        }

        angular.module("app").controller("salesOrderSunUpdateCtrl", controllers.sales.SalesOrderSunUpdateCtrl);
    }
}